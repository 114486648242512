import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import VerifiedSmsService from '@/services/verified-sms.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import getEnv from '@/util/env'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import countryService from '@/services/country.service'
import EventBus from '@/util/EventBus'
import AccountService from '@/services/account.service.js'
import _ from 'lodash'
import { Country } from '../../../../../util/Country'

export default {
  name: 'GoogleVerifiedRequest',
  components: {
    HeaderTopDashboard,
    StatsTitle,
    DateRange,
    ButtonMultimedia,
    OverlayManager,
  },
  data: function () {
    return {
      AVAILABLE_COUNTRIES: [
        'ES',
        'US',
        'CA',
        'MX',
        'BR',
        'GB',
        'FR',
        'IT',
        'IN',
        'PH',
      ],
      loading: true,
      options: {},
      editedIndex: -1,
      editedItem: this.initVerified(),
      defaultItem: this.initVerified(),
      showSentEmail: false,
      showSentEmailDialog: false,
      countries: [],
      senderErrorMessages: null,
      senderMaxLength: 11,
      billingProfiles: [],
      show: false,
      changed: false,
      originalForm: {},
      Country: Country,
    }
  },
  methods: {
    initVerified () {
      return {
        id: '',
        name: '',
        description: '',
        logo: '',
        senders: [],
        sender: {
          name: null,
          country: null,
        },
        company_employee_name: '',
        company_employee_email: '',
        partner_name: '360NRS',
        partner_email: 'verified.sms@360nrs.com',
      }
    },
    isValidLogo () {
      return (
        this.editedItem.logo !== '' && this.editedItem.logo !== 'undefined'
      )
    },
    setThumbnail (url) {
      this.editedItem.logo = getEnv('VUE_APP_ASSETS_URL') + url
    },
    save () {
      const editedItem = {
        ...this.editedItem,
        senders: this.editedItem.senders.map((s) => Object.values(s).join()),
      }
      delete editedItem.sender
      if (this.editedItem.id === '') {
        this.loading = true
        VerifiedSmsService.postDataForm(editedItem)
          .then(
            (response) => {
              this.resetForm()
              this.$router.push({ name: 'GoogleVerified' })
            },
            (err) => {
              let errorTxt = ''
              Object.values(err.response.data.errors).forEach((error) => {
                errorTxt += error[0] + '<br>'
              })
              EventBus.$emit('showAlert', 'danger', errorTxt)
            },
          )
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = true
        VerifiedSmsService.updateDataForm(editedItem)
          .then(
            (response) => {
              this.$router.push({ name: 'GoogleVerified' })
            },
            (err) => {
              let errorTxt = ''
              Object.values(err.response.data.errors).forEach((error) => {
                errorTxt += error[0]
              })
              EventBus.$emit('showAlert', 'danger', errorTxt)
            },
          )
          .finally(() => {
            this.loading = false
          })
      }
    },
    editItem (item) {
      this.editedItem = Object.assign(this.initVerified(), item)
      this.originalForm = _.cloneDeep(this.editedItem)
    },
    createVerified () {
      this.editedItem = this.initVerified()
      this.originalForm = _.cloneDeep(this.editedItem)
    },
    resetForm () {
      this.resetVeeValidate()
    },
    resetVeeValidate () {
      this.$refs.observerForm.reset()
    },
    addSender () {
      this.editedItem.senders.push({ ...this.editedItem.sender })
      this.editedItem.sender = {
        name: null,
        country: null,
      }
    },
    removeSender (idx) {
      this.editedItem.senders.splice(idx, 1)
    },
    getSenderCountry (sender) {
      return (
        this.countries.find(
          (e) => e.value.toLowerCase() === sender.country?.toLowerCase(),
        )?.text || this.$t('(No es necesario por ser numérico)')
      )
    },
    isObject (item) {
      return typeof item !== 'object'
    },
    getCountries () {
      countryService
        .getCountries()
        .then(
          (countries) => {
            for (const key in countries) {
              if (this.AVAILABLE_COUNTRIES.indexOf(key) >= 0) {
                const element = countries[key]
                this.countries.push({ value: key, text: element })
              }
            }
          },
          () => {},
        )
        .finally(() => {})
    },
  },
  computed: {
    /* eslint-disable complexity */
    isValidSender () {
      this.senderErrorMessages = null
      const hasName = this.editedItem.sender.name?.length
      const hasPrefix = this.editedItem.sender.name?.indexOf('+') === 0
      const isPhone = /^\+?[0-9]{7}/
      const isPhoneric = /^\+?[0-9]+$/
      const isNumeric = /^[0-9]+/
      const longitude = this.editedItem.sender.name?.length || 0
      const hasCountry = this.editedItem.sender?.country || null

      const isValidPhone = isPhone.test(this.editedItem.sender.name)
      const isPossiblePhone = isPhoneric.test(this.editedItem.sender.name)
      const isOnlyNumbers = isNumeric.test(this.editedItem.sender.name)

      if (!hasName) {
        this.senderErrorMessages = this.$t(
          'El número o nombre del remitente es un dato obligatorio.',
        )
        return false
      }

      if (hasPrefix && isPossiblePhone) {
        this.senderMaxLength = 15
        if (!isValidPhone || longitude < 8) {
          this.senderErrorMessages = this.$t(
            'La longitud del teléfono ha de ser como mínimo de 7 caracteres.',
          )
          return false
        }
      } else {
        this.senderMaxLength = 11
        if (longitude > 11) {
          this.senderErrorMessages = this.$t(
            'La longitud del remitente ha de ser como máximo de 11 caracteres.',
          )
          return false
        }
        if (isOnlyNumbers) {
          this.senderErrorMessages = this.$t(
            'En caso de ser un número de teléfono hace falta indicar el prefijo de país (+XX).',
          )
          return false
        }
        if (!hasCountry && !this.senderErrorMessages) {
          this.senderErrorMessages = this.$t(
            'El remitente necesita un país de origen.',
          )
          return false
        }
      }

      return true
    },
    hasPrefix () {
      return this.editedItem.sender.name?.indexOf('+') === 0
    },
    isPossiblePhone () {
      return /^\+?[0-9]+$/.test(this.editedItem.sender.name)
    },
    isFormInvalid () {
      return this.$refs.observerForm.flags.invalid
    },
  },
  watch: {
    editedItem: {
      handler () {
        this.changed = !_.isEqual(this.editedItem, this.originalForm)
      },
      deep: true,
    },
  },
  created () {
    AccountService.getBilling().then(
      (response) => {
        this.billingProfiles = response.billingProfiles
        this.show = true

        const requestId = this.$route.params.id

        if (requestId) {
          VerifiedSmsService.getDataForm(requestId)
            .then(
              (response) => {
                this.editItem(response.editData)
              },
              () => {},
            )
        } else {
          this.createVerified()
        }

        this.getCountries()
      },
      () => {},
    )
  },
}
